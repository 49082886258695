body {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f7d3e3;
  overflow: hidden;
}

.title {
  font-size: 3em;
  font-weight: bold;
  color: #721c24;
  animation: slideUp 2s ease-out 2s forwards;
  animation-fill-mode: both;
  transition: opacity 1s ease-in-out;
  margin-bottom: 20px;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.button-56 {
  background-color: #f7d3e3;
  border: 2px solid #721c24;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  font-family: Inter, sans-serif;
  font-size: 16px;
  height: 48px;
  line-height: 24px;
  width: 200px; /* Set a fixed width for all buttons */
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  opacity: 0;
  animation: fadeIn 2s ease-out 4s forwards;
  animation-fill-mode: both;
  margin-bottom: 20px;
  position: relative;
}

.button-56:after {
  background-color: #721c24;
  border-radius: 8px;
  content: "";
  display: block;
  height: 48px;
  left: 0;
  width: 100%;
  position: absolute;
  top: -2px;
  transform: translate(8px, 8px);
  transition: transform 0.2s ease-out;
  z-index: -1;
}

.button-56:hover:after {
  transform: translate(0, 0);
}

.button-56:active {
  background-color: #721c24;
  outline: 0;
}

.button-56:hover {
  outline: 0;
}

.button-56:nth-child(1) {
  top: 50%;
}

.button-56:nth-child(2) {
  top: 70%;
}

@media (min-width: 768px) {
  .button-56 {
    padding: 0 40px;
  }
}

/* Add a fade-in animation for the button */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(-50%);
  }
  100% {
    transform: translateY(-350%);
  }
}

.image-container {
  --s: 5px; /* Adjust the size to be half (50%) of the original size */
  padding: var(--s);
  border: calc(2 * var(--s)) solid #0000;
  outline: 1px solid #000;
  outline-offset: calc(-1 * var(--s));
  background: conic-gradient(from 90deg at 1px 1px, #0000 25%, #000 0);
  max-width: 50%; /* Set the maximum width to be 50% of the container width */
  margin: 0 auto; /* Center the container on the page */
}

.image {
  width: 100%; /* Make the image width fill the container */
  height: auto; /* Maintain the aspect ratio */
  max-height: 100%; /* Ensure the image doesn't exceed the container height */
  object-fit: contain; /* Keep the entire image within the container without cropping */
  transition: transform 0.5s; /* Optional: Add a smooth transition effect */
}

.imageButton {
  background-color: #f7d3e3;
  border: 2px solid #721c24;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  font-family: Inter, sans-serif;
  font-size: 16px;
  height: 48px;
  line-height: 24px;
  padding: 0 25px;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.button-56.image-page {
  background-color: #f7d3e3;
  border: 2px solid #721c24;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  font-family: Inter, sans-serif;
  font-size: 16px;
  height: 48px;
  line-height: 24px;
  padding: 0 25px;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  position: absolute;
  top: 80%; /* Adjust the position as needed */
  left: 50%;
  transform: translateX(-50%);
  opacity: 1; /* Remove animation opacity */
}

.button-56.image-page:after {
  background-color: #721c24;
  border-radius: 8px;
  content: "";
  display: block;
  height: 48px;
  left: 0;
  width: 100%;
  position: absolute;
  top: -2px;
  transform: translate(8px, 8px);
  transition: transform 0.2s ease-out;
  z-index: -1;
}

.button-56.image-page:hover:after {
  transform: translate(0, 0);
}

.button-56.image-page:active {
  background-color: #ffdeda;
  outline: 0;
}

.button-56.image-page:hover {
  outline: 0;
}

/* Remove animation styles */
.button-56.image-page {
  animation: none;
}

@media (min-width: 768px) {
  .button-56.image-page {
    padding: 0 40px;
  }
}

.countdown {
  position: fixed;
  top: 13%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f7d3e3;
  padding: 10px;
  color: #721c24;
  font-size: 24px;
  font-family: serif;
  font-style: oblique;
  font-weight: bold;
  max-width: 100%;
  white-space: nowrap;
}

.quote-container {
  text-align: center;
  margin: 20px;
}

.quote {
  font-size: 1.5em;
  font-style: italic;
  color: #721c24;
}

.author {
  margin-top: 10px;
  font-size: 1.2em;
  color: #721c24;
}